import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

/**
 * Assets
 */
import './assets/styles/_index.scss';

const ListImageBullets = ({list, className}) => {
  const [items, setItems] = useState(list);
  
  const updateActive = (ind) => {
    const updatedItems = items.map((item, itemIndex) => {
      if(ind === itemIndex){
        item.active = !item.active;
      } else {
        item.active = false;
      }
      return item;
    });
    setItems(updatedItems);
  }

  useEffect(() => {
    const updatedList = list.map(item => {
      item.active = false;
      return item;
    });
    setItems(updatedList);
  }, [list])

  return (
    <ul className={classNames('image-bullets', className)}>
      {items.map((item,ind) => {
      if(item.URL != null) {
        if ((item.URL.match('http')) || (item.URL.match('https'))) {
          var CTA_1 = "external"
        }
        else {
          var CTA_1 = "internal"
        }
      }

          return <li key={ind} className={classNames({'is-active': item.active})}>


          {CTA_1 == "internal" &&
            <Link to={`/${item.URL}`} onMouseEnter={() => updateActive(ind)} onMouseLeave={() => updateActive(ind)}>
              <div className="bullet" style={{backgroundImage: `url(${item.Thumbnail_Image.publicURL})`}}></div>
              {item.Label}
            </Link>
          }
          {CTA_1 == "external" &&
            <a href={item.URL} target="_blank" onMouseEnter={() => updateActive(ind)} onMouseLeave={() => updateActive(ind)}>
              <div className="bullet" style={{backgroundImage: `url(${item.Thumbnail_Image.publicURL})`}}></div>
              {item.Label}
            </a>
          }


          </li>
        }
      )}
    </ul>
  )
}

export default ListImageBullets
